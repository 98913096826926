<template>
  <v-layout wrap>
    <v-flex xs9 style="padding: 20px 0 20px 20px">
      <v-card v-if="forms">
        <v-layout wrap
          style="padding:10px 20px;"
        >
          <v-flex xs7 style="text-align: left;" class="result-head">
          </v-flex>
          <v-flex xs5 class="result-head"
          >
            <center>{{ $t('turismo.survey.results', locale) }}</center>
          </v-flex>
        </v-layout>
        <v-layout wrap
          style="padding:10px 20px;"
        >
          <v-flex xs7 style="text-align: left;" class="result-head">
          {{ $t('turismo.survey.questions', locale) }}
          </v-flex>
          <v-flex xs1 class="result-head"
            v-for="r of [5, 4, 3, 2, 1]"
            :key="`header_${r}`"
          >
            <center>{{r}}</center>
          </v-flex>
        </v-layout>

        <v-layout wrap
          v-for="item of forms"
          :key="item.ID"
          style="border-top: 1px solid #CCCCCC;padding:10px 20px;"
        >
          <v-flex xs7 style="text-align: left;" class="result-row">
            {{getTranslation(item.Label, locale)}}
          </v-flex>
          <v-flex xs1 class="result-row"
            v-for="r of [5, 4, 3, 2, 1]"
            :key="`${item.ID}_${r}`"
          >
            <center>{{ getTotal(item.ID, r) }}</center>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs3 style="padding: 20px">
      <v-card>
        <center style="padding-top: 20px;">
          <v-layout wrap style="max-width: 800px">
            <v-flex xs12>
              <center><b style="font-size: 3rem;">{{count}}</b></center>
            </v-flex>
            <v-flex xs12 style="padding-bottom:20px">
              <b style="color: rgba(0, 0, 0, 0.6);">
                {{ $t('turismo.survey.totalPax', locale) }}
              </b>
            </v-flex>
          </v-layout>
        </center>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  props: {
    count: {
      type: Number,
      default: null
    },
    totalResults: {
      type: Array,
      default: null
    },
    results: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    forms: null,
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  mounted () {
    this.handleGetForms()
  },
  methods: {
    handleGetForms () {
      this.loading = true
      api.getAllWithoutLimit ('turismo', `v1/private/surveys/`)
        .then(response => {
          this.forms = response.data.filter(x => !x.Disable && !x.Hide)
          this.loading = false
        })
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    getTotal(id, r) {
      const aux = this.totalResults.filter(x => x.SurveyID === id).shift()
      if (!aux || !aux[`R${r}`]) return 0
      else return aux[`R${r}`]
    },
  },
}
</script>
<style>
.result-head {
  font-weight: 500 !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 1rem !important;
  letter-spacing: .009375em !important;
  line-height: 1.75rem;
  color: rgba(0, 0, 0, 0.6);
}
.result-row {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
</style>

